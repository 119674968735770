import { useContext } from "react";
import cookie from "react-cookies";

export const uploadDelete = (table, data, callback) => {
  let d = [];
  data.map((a, i) => {
    d.push(a);
  });
  let selected = table.handleGetSelectedData();
  table.setState({
    selectedRowKeys: [],
  });
  let num = 0;
  selected.map((x) => {
    d.splice(x - num, 1);
    num++;
  });
  d.map((item, index) => {
    item.id = index;
  });
  callback(d);
};
export const subcategoryColumns = [
  {
    dataField: "id",
    text: "Id",
    sort: true,
  },
  {
    dataField: "name",
    text: "Channel Name",
    sort: true,
  },
];

export const updateAttention = (
  oldValue,
  newValue,
  row,
  column,
  stateHandle
) => {
  if (oldValue != newValue && column.dataField == "attention") {
    let attention;
    if (newValue == "yes") {
      attention = 1;
    }
    if (newValue == "no") {
      attention = 0;
    }

    let formData = new FormData();
    formData.append("action", "edit attention");
    formData.append("id", row.id);
    formData.append("attention", attention);
    formData.append("studentId", stateHandle.state.studentId);
    var url = "http://ohrhatorah.appsenses.com/lesson.php";

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.map((item) => {
          item.id = parseInt(item.id);
        });
        stateHandle.setState({ studentData: res });
      });
  }
};
export const singleMissingMarkSubmit = (
  oldValue,
  newValue,
  row,
  column,
  testId,
  callback
) => {};
export const updateTest = (
  oldValue,
  newValue,
  row,
  column,
  testId,
  callback
) => {
  if (oldValue != newValue) {
    let url = "";

    // if (column.dataField == "first_name") {
    //     url=baseUrl+`/update/first/${row.student_id}/${newValue}`
    // }
    // if (column.dataField == "last_name") {
    //     url=baseUrl+`/update/last/${row.student_id}/${newValue}`
    // }
    // if (column.dataField == "note") {
    //     url=baseUrl+`/update-test-note/${row.testStudentId}/${newValue}`
    // }

    newValue = parseInt(newValue);
    if (column.dataField == "mark") {
      if (!(typeof newValue === "number" && isFinite(newValue))) {
        console.log("not a valid mark");
        return "not a valid mark";
      }
      if (!row.testStudentId) row.testStudentId = -1;
      let thisTestId = row.test_id;
      if (!thisTestId) {
        thisTestId = testId;
      }
      url = `${baseUrl}/update-test/${row.testStudentId}/${newValue}/${thisTestId}/${row.student_id}`;
    }
    if (column.dataField == "retake") {
      if (!(typeof newValue === "number" && isFinite(newValue))) {
        console.log("not a valid retake");
        return "not a valid retake";
      }
      if (row.retakeId != null) {
        newValue = parseInt(newValue);

        url = `${baseUrl}/update-retake/${row.retakeId}/${newValue}`;
      } else {
        url = `${baseUrl}/submit-retake/${row.student_id}/${row.test_id}/${newValue}`;
      }
    }

    fetch(url, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.text();
      })
      .then((res) => {
        console.log(res);
        callback(res);
      });
  }
};

export const updatePoints = (oldValue, newValue, row, column, callback) => {
  if (oldValue != newValue) {
    if (column.dataField == "points") {
      let formData = new FormData();
      formData.append("action", "edit points");
      formData.append("id", row.id);
      formData.append("points", newValue);

      var url = "http://ohrhatorah.appsenses.com/bank.php";

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          res.map((item) => {
            item.id = parseInt(item.id);
          });
          callback(res);
        });
    } else if (column.dataField == "seat") {
      let formData = new FormData();
      formData.append("action", "edit seat");
      formData.append("id", row.id);
      formData.append("seat", newValue);

      var url = "http://ohrhatorah.appsenses.com/students.php";

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {});
    }
  }
};

//let baseUrl = "https://ibisedsolutions.com/school/public/api";
let baseUrl;
let loc = window.location.href;
if (loc.indexOf(".")) loc = loc.substr(0, loc.indexOf("."));

//baseUrl="http://localhost:8888/school/public/api";
//baseUrl="https://kaminetz.ibisedsolutions.com/school/public/api";

export let schoolName;

//export let schoolName="Kaminetz"
export let reportCardSchoolName = "ישיבת אור התורה";
//export let reportCardSchoolName="ישיבת בית ראובן קמניץ"
var subdomain = window.location.host.split(".")[1]
  ? window.location.host.split(".")[0]
  : false;
export let newPhoneNumb = false;
export let isDev = false;
export let hasShagDay = false;
export let isTTOL = false;
export let isOhrHachaim = false;
export let isImreiShefer = false;
export let hasTzedakaTab;
export let bankAverage = false;
hasTzedakaTab = false;
export let reportCardSchoolAddress;
export let noteDropDownOptions;
export let showCommentsOnReportCard = true;
export let useModelPerDay = false;
export let showWorkPoints = false;
export let minimalDisplay = false;
//export let average;
export let reportCardLogo = require("./ohr_hatorah_logo.jpg");
if (subdomain === "ibisedsolutions") {
  schoolName = "Ohr Hatorah";
  reportCardSchoolName = "ישיבת אור התורה";
  baseUrl = "https://ibisedsolutions.com/school/public/api";
  hasTzedakaTab = true;
  newPhoneNumb = true;
  reportCardSchoolAddress = "780 Vassar Ave.";
} else if (subdomain === "shagasaryeh") {
  schoolName = "Shagas Aryeh";
  newPhoneNumb = true;
  reportCardSchoolName = "ישיבת שאגת אריה";
  baseUrl = "https://shagasaryeh.ibisedsolutions.com/school/public/api";
  hasShagDay = true;
} else if (subdomain === "dev") {
  schoolName = "Developer";
  reportCardSchoolName = "developer school";
  baseUrl = "https://dev.ibisedsolutions.com/school/public/api";
  hasTzedakaTab = true;
  isDev = true;
  bankAverage = true;
  newPhoneNumb = true;
  hasShagDay = true;
} else if (subdomain === "talmudtorah") {
  isTTOL = true;
  newPhoneNumb = true;
  schoolName = "Talmud Torah Of Lakewood";
  reportCardSchoolName = "תלמוד תורה דליקווד";
  reportCardSchoolName = "TALMUD TORAH OF LAKEWOOD";
  reportCardSchoolAddress = "1464 East Spruce Street";
  baseUrl = "https://talmudtorah.ibisedsolutions.com/school/public/api";
  reportCardLogo = require("./TTlogo.png");
  bankAverage = true;
  noteDropDownOptions = [
    "Think sheet given. Disturbing",
    "Think sheet given. Chutzpah",
    "Think sheet given. Fighting",
    "Assignment given.",
    "Think sheet and assignment given.",
    "Think sheet given second offence.",
    "Think sheet given third offence.",
  ];
} else if (subdomain === "ohrhachaim") {
  schoolName = "Ohr Hachaim";
  newPhoneNumb = true;
  isOhrHachaim = true;
  minimalDisplay = true;
  reportCardSchoolName = "אור החיים";
  baseUrl = "https://ohrhachaim.ibisedsolutions.com/school/public/api";
  bankAverage = true;
  // gets shgasAryeh's
  // hasShagDay = true;
} else if (subdomain === "imreishefer") {
  isImreiShefer = true;
  schoolName = "Imrei Shefer";
  newPhoneNumb = true;
  reportCardSchoolName = "Imrei Shefer";
  baseUrl = "https://imreishefer.ibisedsolutions.com/school/public/api";
} else if (subdomain === "ohrshraga") {
  schoolName = "Ohr Shraga";
  newPhoneNumb = true;
  reportCardSchoolName = "Ohr Shraga";
  baseUrl = "https://ohrshraga.ibisedsolutions.com/school/public/api";
} else if (subdomain === "rachmistrivke") {
  schoolName = "Rachmastrivka";
  newPhoneNumb = true;
  reportCardSchoolName = "Rachmastrivke";
  baseUrl = "https://rachmistrivke.ibisedsolutions.com/school/public/api";
  reportCardLogo = require("./Logos/rachmastrivke.jpg");
  showCommentsOnReportCard = false;
} else if (subdomain === "nesivoshatorah") {
  schoolName = "Nesivos Hatorah";
  newPhoneNumb = true;
  reportCardSchoolName = "Nesivos Hatorah";
  useModelPerDay = true;
  bankAverage = false;
  showWorkPoints = false;
  reportCardLogo = require("./Logos/Nesivos.jpg");
  baseUrl = "https://nesivoshatorah.ibisedsolutions.com/school/public/api";
} else if (subdomain === "beishachinuch") {
  schoolName = "Beis Hachinuch";
  newPhoneNumb = true;
  reportCardSchoolName = "Beis Hachinuch";
  baseUrl = "https://beishachinuch.ibisedsolutions.com/school/public/api";
} else if (subdomain === "eider") {
  schoolName = "Eider";
  newPhoneNumb = true;
  reportCardSchoolName = "Eider";
  baseUrl = "https://eider.ibisedsolutions.com/school/public/api";
  // }
} else if (subdomain === "cliftoncheder") {
  schoolName = "Clifton Cheder";
  newPhoneNumb = true;
  reportCardSchoolName = "Clifton Cheder";
  baseUrl = "https://cliftoncheder.ibisedsolutions.com/school/public/api";
  useModelPerDay = true;
  bankAverage = true;
  showWorkPoints = true;
} else if (subdomain === "toraszev") {
  schoolName = "Toras Zev";
  newPhoneNumb = true;
  reportCardSchoolName = "Toras Zev";
  baseUrl = "https://toraszev.ibisedsolutions.com/school/public/api";
} else {
  schoolName = "Nesivos Hatorah";
  newPhoneNumb = true;
  reportCardSchoolName = "Nesivos Hatorah";
  useModelPerDay = false;
  bankAverage = true;
  showWorkPoints = false;
  isOhrHachaim = true;
  baseUrl = "https://nesivoshatorah.ibisedsolutions.com/school/public/api";
}
// baseUrl="https://dev.ibisedsolutions.com/school/public/api";
//baseUrl = "http://localhost:8888/school/public/api";
// baseUrl = "http://localhost/ibds-project/ibisedsolutions_be/public/api";
// isDev = true;
// baseUrl = "http://127.0.0.1:8000/api";
// baseUrl = "http://localhost:8888/school/public/api";
// baseUrl = "http://localhost/ibisedsolutions_be/public/api";// eli moskowitz
//baseUrl = "http://localhost/ibds-project/ibisedsolutions_be/public/api";
// isDev = true;
// newPhoneNumb = true;
// hasTzedakaTab = true;
// bankAverage = true;
export default baseUrl;

export const roundAverage = (average) => {
  if (isNaN(average)) {
    return average;
  }
  let number = Number(average);
  // rounds to one decimal place
  number = number.toFixed(1);
  // cast to number to remove trailing zeros
  return Number(number);
};

export const randomColor = () => {
  let colorArray = [];

  for (let i = 0; i < 3; i++) {
    colorArray.push(Math.floor(Math.random() * (255 - 0) + 0));
  }
  // rgb -> hex
  let color = colorArray.map((x) => x.toString(16)).join("");

  return `#${color}`;
};
export const getGrades = (context) => {
  fetch(baseUrl + "/grades", {
    headers: {
      Authorization: authentication,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      res.forEach((a) => {
        a.id = parseInt(a.id);
      });
      context.setState({ grades: res });
    });
  return;
};
export const getClasses = (gradeId, context) => {
  let url = "";
  if (gradeId) {
   url =  `${baseUrl}/grade/${gradeId}`;
  } else {
    url = `${baseUrl}/allClasses`;
  }
  fetch(url, {
    headers: {
      Authorization: authentication,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      res.forEach((a) => {
        a.id = parseInt(a.id);
      });
      context.setState({ classes: res });
    });
};

export const getTeachers = (context) => {
  fetch(baseUrl + "/teachers", {
    headers: {
      Authorization: authentication,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      res.forEach((a) => {
        a.id = parseInt(a.id);
      });
      context.setState({ teachers: res });
    });
};
export const getSubjects = (context) => {
  return fetch(baseUrl + "/subjects", {
    headers: {
      Authorization: authentication,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      res.forEach((a) => {
        a.id = parseInt(a.id);
      });
      if (context) {
        context.setState({ subjects: res });
      } else {
        return res;
      }
      });
};
export const getSubjectsForDropdown = async () => {
  return getSubjects(false).then((response) => {
    return response.map((a) => {
      return {value: a.id,
              label: a.name};
    });;
  });
}

export const deductPoints = (id, points) => {
  fetch(baseUrl + `/points-deduct/${id}/${points}`, {
    headers: {
      Authorization: authentication,
    },
  });
};
export const updateFirstName = (id, name) => {
  fetch(baseUrl + `/update/first/${id}/${name}`, {
    headers: {
      Authorization: authentication,
    },
  });
};
export const updateLastName = (id, name) => {
  fetch(baseUrl + `/update/last/${id}/${name}`, {
    headers: {
      Authorization: authentication,
    },
  });
};
export const updateStudentClass = (id, clas) => {
  fetch(baseUrl + `/update-student/class/${id}/${clas}`, {
    headers: {
      Authorization: authentication,
    },
  });
};

export const setNewTestClass = (classId, context) => {
  let url = `${baseUrl}/class-students/${classId}`;
  fetch(url, {
    headers: {
      Authorization: authentication,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      res.map((item) => {
        item.id = parseInt(item.id);
        item.note = "";
        item.mark = "";
        item.excused = "";
        context.info.push(item);
      });

      context.setState({ studentData: res });
    });
};

export const goToGrade = (context, grade, gradeId) => {
  context.props.history.push({
    pathname: "/grade-details",
    state: { grade: grade, gradeId: gradeId },
  });
};
export const setPassword = (password) => {
  authentication = password;
  console.log(authentication);
};
export const addBankAverage = (studentData) => {
  return fetch(`${baseUrl}/current-average`, {
    headers: {
      Authorization: authentication,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((averageRes) => {
      // averageRes.forEach(element=>count++)
      // console.log(Object.keys(averageRes).length)
      // console.log(studentData)
      studentData.map((student) => {
        let student_id = student.id;
        student.average = averageRes[student_id]
          ? averageRes[student_id]
          : "NA";
      });
      return studentData;
    });
};
export const loginFunction = (user, pass, callback) => {
  let formData = new FormData();
  formData.append("username", user);
  formData.append("password", pass);
  const url = baseUrl + "/login";

  fetch(url, {
    method: "POST",
    body: formData,
    headers: {
      Authorization: authentication,
    },
  })
    .then((response) => {
      return response.json();
    })
    .then((res) => {
      if (res[0] && res[0].id) {
        localStorage.setItem("authentication", "BYk9X6DgBvOrytMPwZ6qmAg");
        localStorage.setItem("userInfo", JSON.stringify(res[0]));
        callback();
      } else if (res.login === "no auth") {
        alert("Wrong Credentials! Please try again");
      }
    })
    .catch((e) => {
      console.error(e);
      alert(e);
    });
};

export let authentication = localStorage.getItem("authentication");

export const updateChannel = (oldValue, newValue, row, column) => {
  console.log(column);
  if (newValue != oldValue) {
    let formData = new FormData();
    if (column.dataField == "name") {
      formData.append("action", "name");
      formData.append("name", newValue);
    } else {
      formData.append("action", "image");
      formData.append("image", newValue);
    }
    formData.append("id", row.id);

    fetch(
      "https://cors-anywhere.herokuapp.com/https://www.appsenses.com/jflix/web/updateChannel.php",
      {
        method: "POST",
        body: formData,
        headers: {
          Authorization: authentication,
        },
      }
    );
  }
};
export let formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const setClass = async (context,classId=0) => {
  let res = await getStudentData(classId);
  context.setState({ studentData: res });
};
  
export const getStudentData = (classId=0) => {
const modelStudentMarks = {};
let url = `${baseUrl}/bank`;
if (useModelPerDay) {
  url = `${baseUrl}/bank-with-model`;
}
if (classId){
  url += `/${classId}`;
}
return fetch(url, {
  headers: {
    Authorization: authentication,
  },
})
  .then((response) => {
    return response.json();
  })
  .then(async (res) => {
    res.forEach((item) => {
      if (item.last_name.endsWith("!!") && item.marks) {
        modelStudentMarks[item.class] = item.marks;
      }
    });
    res.map((item) => {
      item.id = parseInt(item.id);
      item.points = parseInt(item.points);
      item.tests = "";
      item.missingTests.map((test, index) => {
        if (index != 0) {
          item.tests += " | ";
        }
        item.tests += test.topic;
      });
      item.retake = "";
      item.retakes.map((test, index) => {
        if (index != 0) {
          item.retake += " | ";
        }
        item.retake += test.topic;
      });
      item.percentOfModel = (item.marks && modelStudentMarks[item.class] )  ? roundAverage(item.marks / (modelStudentMarks[item.class]) * 100) +"%" : '';
    });
    if (bankAverage) {
      res = await addBankAverage(res);
    }
    return res;
  });
};
